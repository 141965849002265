<div
  class="pl-provider-details-header"
  *ngIf="provider.hasOwnProperty('user'); else loading"
>
  <div class="pl-provider-details-header-inner">
    <div>
      <div class="provider-details-container">
        <img
          class="provider-image"
          [src]="providerPhotoUrl"
          [alt]="provider.first_name + ' ' + provider.last_name + ' photo'"
        />
        <div class="provider-details-container-inner">
          <div class="heading">
            <div class="name">
              {{ provider.first_name }}
              {{ provider.last_name }}
            </div>

            <button
              mat-stroked-button
              color="primary"
              type="button"
              *ngIf="showAssumeButton"
              (click)="assumeUser()"
              title="Assume provider"
            >
              Assume provider
            </button>
          </div>
          <div class="details">
            <div
              class="status"
              [ngClass]="{
                inactive: getStatus() === 'Inactive',
                active: getStatus() === 'Active',
                'on-hold': getStatus() === 'On Hold',
                onboarding: getStatus() === 'Onboarding'
              }"
            >
              {{ getStatus() }}
            </div>
            <div class="title">
              {{ provider.provider_type_details[0].long_name }}
            </div>
            <a
              *ngIf="userType !== 'CUSTOMER'"
              href="https://plearn.lightning.force.com/lightning/r/Contact/{{
                provider.salesforce_id
              }}/view"
              ><span>View in Salesforce</span
              ><mat-icon>open_in_new</mat-icon></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="fast-stats" *ngIf="userType !== 'CUSTOMER'">
      <div class="card">
        <div class="value">{{ provider.caseload_clients_count }}</div>
        <div class="label">Active students</div>
      </div>
      <div class="card">
        <div id="remaining-hours" class="value">
          {{ desiredHours - assignedHours | number : '1.0-2' }}
        </div>
        <div class="label">Remaining hours</div>
      </div>
      <div class="card">
        <div id="assigned-hours" class="value">
          {{ assignedHours | number : '1.0-2' }}
        </div>
        <div class="label">Assigned hours</div>
      </div>
      <div class="card">
        <div id="desired-hours" class="value">
          {{ desiredHours | number : '1.0-2' }}
        </div>
        <div class="label">Desired hours</div>
      </div>
    </div>
  </div>
  <nav mat-tab-nav-bar ngClass="tabs">
    <a
      mat-tab-link
      [routerLink]="['/provider', provider.user, 'overview']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Overview</a
    >
    <a
      mat-tab-link
      [routerLink]="['/provider', provider.user, 'locations']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Locations</a
    >
    <a
      mat-tab-link
      *ngIf="userType !== 'CUSTOMER'"
      [routerLink]="['/provider', provider.user, 'qualifications']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Qualifications</a
    >
    <a
      mat-tab-link
      *ngIf="userType !== 'CUSTOMER'"
      [routerLink]="['/schedule', 'calendar', provider.user]"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Schedule</a
    >
    <a
      mat-tab-link
      *ngIf="userType !== 'CUSTOMER'"
      [routerLink]="['/provider', provider.user, 'assignments']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Assignments</a
    >
    <a
      mat-tab-link
      *ngIf="userType !== 'CUSTOMER'"
      [routerLink]="['/provider', provider.user, 'availability']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Availability</a
    >
    <a
      mat-tab-link
      *ngIf="userType !== 'CUSTOMER'"
      [routerLink]="['/provider', provider.user, 'provider-pay-rates']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      >Pay rates</a
    >
  </nav>
</div>

<ng-template #loading>
  <div>Loading...</div>
</ng-template>
