import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { PLProviderAvailabilityService } from '@app/modules/locations/services';
import { PLPipesModule } from '@common/pipes';
import { PLProviderSignedAgreementsDetailsComponent } from '@modules/providers/pl-provider-details/pl-provider-details-signed-agreements-details.component';
import { PLProviderSignedAgreementsComponent } from '@modules/providers/pl-provider-details/pl-provider-details-signed-agreements.component';
import { CurrentUserService } from '@modules/user/current-user.service';
import {
  PLButtonModule,
  PLDotLoaderModule,
  PLIconModule,
  PLMayService,
  PLTableFrameworkModule,
} from '@root/src/lib-components';
import { PLUserPhotoService } from '@root/src/lib-components/pl-user-photos/pl-user-photo.service';
import { PLProviderDetailsAdditionalDetailsComponent } from './pl-provider-details-additional-details.component';
import { PLProviderDetailsAssignmentPreferencesComponent } from './pl-provider-details-assignment-preferences.component';
import { PLProviderDetailsBasicInformationComponent } from './pl-provider-details-basic-information.component';
import { PLProviderDetailsContactInformationComponent } from './pl-provider-details-contact-information.component';
import { PLProviderDetailsHeaderComponent } from './pl-provider-details-header.component';
import { PLProviderDetailsOrganizationPreferencesComponent } from './pl-provider-details-organization-preferences.component';
import { PLProviderDetailsRoomDetailsComponent } from './pl-provider-details-room-details.component';
import { PLProviderDetailsServiceDetailsComponent } from './pl-provider-details-service-details.component';
import { PLProviderDetailsTechCheckDetailsComponent } from './pl-provider-details-tech-check-details.component';
import { PLProviderDetailsComponent } from './pl-provider-details.component';
import { PLProvidersService } from '../../schedule/services';
import { PLProviderAccountSettingsComponent } from '../pl-provider-account-settings/pl-provider-account-settings.component';

const routes: Routes = [
  {
    path: '',
    component: PLProviderDetailsComponent,
  },
];

@NgModule({
  declarations: [
    PLProviderDetailsAdditionalDetailsComponent,
    PLProviderDetailsAssignmentPreferencesComponent,
    PLProviderDetailsBasicInformationComponent,
    PLProviderDetailsComponent,
    PLProviderDetailsContactInformationComponent,
    PLProviderDetailsOrganizationPreferencesComponent,
    PLProviderDetailsRoomDetailsComponent,
    PLProviderDetailsTechCheckDetailsComponent,
    PLProviderDetailsServiceDetailsComponent,
    PLProviderSignedAgreementsComponent,
    PLProviderSignedAgreementsDetailsComponent,
    PLProviderAccountSettingsComponent,
    PLProviderDetailsHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatChipsModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    PLIconModule,
    PLTableFrameworkModule,
    PLDotLoaderModule,
    PLButtonModule,
    PLPipesModule,
  ],
  providers: [
    PLProviderAvailabilityService,
    PLProvidersService,
    PLMayService,
    CurrentUserService,
    PLUserPhotoService,
  ],
  exports: [PLProviderDetailsHeaderComponent],
})
export class PLProviderDetailsModule {}
