import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { MizarService } from '../api/mizar/mizar.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStore } from '@root/src/app/appstore.model';
import { PLUserPhotoService } from '../pl-user-photos/pl-user-photo.service';
import { selectCurrentUser } from '@root/src/app/common/store';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'pl-top-bar',
  templateUrl: './pl-top-bar.component.html',
  styleUrls: ['./pl-top-bar.component.less'],
})
export class PLTopBarComponent implements OnInit {
  @ViewChild('menuContainer') menuContainer: ElementRef;
  @Output() navToggle = new EventEmitter<void>();

  @Input() user: any = {};
  @Input() userMenuLinks: any[];
  @Input() isAssumed: boolean = false;
  @Input() toggleNav: () => void;
  @Input() userType: string;
  @Input() collapsable: boolean = true;

  homeUrl: string;
  userPicture: SafeUrl;
  userAvatar: SafeUrl;

  constructor(
    private userPhotoService: PLUserPhotoService,
    mizarService: MizarService,
    route: ActivatedRoute,
    store: Store<AppStore>,
  ) {
    this.homeUrl =
      this.userType === 'CAM'
        ? '/cam-dashboard/overview'
        : this.userType === 'CUSTOMER'
        ? '/dashboard'
        : '/landing';
  }

  ngOnInit() {
    this.userPhotoService.setUserUuid(this.user.uuid);
    this.userPhotoService.getPresignedUrlWithMizar().subscribe(url => {
      this.userPicture = url;
    });
    this.userPhotoService
      .getPresignedUrlWithMizar({ useInitials: true })
      .subscribe(url => {
        this.userAvatar = url;
      });
  }
}
