import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MizarService } from '../api/mizar/mizar.service';
import { AppStore } from '@root/src/app/appstore.model';
import { selectCurrentUser } from '@root/src/app/common/store';
import { catchError, switchMap, tap, map, filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, EMPTY } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface UserDetails {
  image_count: number;
}

@Injectable({
  providedIn: 'root',
})
export class PLUserPhotoService {
  USER_ICON_SVG = this.sanitizer.bypassSecurityTrustResourceUrl(
    'data:image/svg+xml;base64,' +
      btoa(
        '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"/></g></svg>',
      ),
  );

  private userPictureSubject = new BehaviorSubject<SafeUrl>(this.USER_ICON_SVG);
  private userInitialsSubject = new BehaviorSubject<SafeUrl>(
    this.USER_ICON_SVG,
  );
  private hasPictureSubject = new BehaviorSubject<boolean>(false);
  private overrideUuid: string | null = null;

  userPicture$ = this.userPictureSubject.asObservable();
  userInitials$ = this.userInitialsSubject.asObservable();

  constructor(
    private mizarService: MizarService,
    private store: Store<AppStore>,
    private sanitizer: DomSanitizer,
  ) {
    // Listen to user picture updates
    this.mizarService.userPictureUpdated.subscribe(() => {
      this.refreshUserPicture();
    });
  }

  private refreshUserPicture() {
    const uuid = this.getCurrentUserUuid();
    if (!uuid) return;

    this.mizarService
      .getDetails(uuid)
      .pipe(
        catchError(error => {
          console.error('Error checking image details:', error);
          return of(null);
        }),
        tap(details => {
          this.hasPictureSubject.next(
            !!(details?.image_count && details.image_count > 0),
          );
        }),
        switchMap(details => {
          if (!details?.image_count || details.image_count === 0) {
            this.userPictureSubject.next(this.USER_ICON_SVG);
            this.store
              .select(selectCurrentUser)
              .pipe(
                take(1),
                map(user => {
                  if (user?.first_name && user?.last_name) {
                    return this.createInitialsAvatar(
                      user.first_name,
                      user.last_name,
                    );
                  }
                  return this.USER_ICON_SVG;
                }),
              )
              .subscribe(initialsAvatar => {
                this.userInitialsSubject.next(initialsAvatar);
              });
            return EMPTY;
          }
          return this.mizarService.getUserImage(uuid).pipe(
            catchError(error => {
              console.error('Error getting user image:', error);
              this.userPictureSubject.next(this.USER_ICON_SVG);
              this.store
                .select(selectCurrentUser)
                .pipe(
                  take(1),
                  map(user => {
                    if (user?.first_name && user?.last_name) {
                      return this.createInitialsAvatar(
                        user.first_name,
                        user.last_name,
                      );
                    }
                    return this.USER_ICON_SVG;
                  }),
                )
                .subscribe(initialsAvatar => {
                  this.userInitialsSubject.next(initialsAvatar);
                });
              return EMPTY;
            }),
            tap((res: { image_presigned_url: string }) => {
              const url = this.sanitizer.bypassSecurityTrustResourceUrl(
                res?.image_presigned_url || '',
              );
              this.userPictureSubject.next(url);
              this.userInitialsSubject.next(url);
            }),
          );
        }),
      )
      .subscribe();
  }

  userHasPicture(): Observable<boolean> {
    return this.hasPictureSubject.asObservable();
  }

  getPresignedUrlWithMizar(
    args: { uuid?: string; useInitials?: boolean } = {},
  ): Observable<SafeUrl> {
    if (args.uuid) {
      return this.fetchImageForUuid({ uuid: args.uuid, presigned: true });
    }
    if (args.useInitials) {
      return this.userInitials$;
    }

    return this.userPicture$;
  }

  getImageDataWithMizar(
    args: { uuid?: string; useInitials?: boolean } = {},
  ): Observable<SafeUrl> {
    if (args.uuid) {
      return this.fetchImageForUuid({ uuid: args.uuid, presigned: false });
    }
    if (args.useInitials) {
      return this.userInitials$;
    }
    return this.userPicture$;
  }

  setUserUuid(uuid: string | null) {
    this.overrideUuid = uuid;
    if (uuid) {
      this.refreshUserPicture();
    }
  }

  uploadUserImage(file: File): Observable<any> {
    const uuid = this.getCurrentUserUuid();
    if (!uuid) {
      return of({ error: 'No user UUID available' });
    }

    return this.mizarService.uploadImage(uuid, file).pipe(
      tap(() => {
        this.refreshUserPicture();
      }),
      catchError(error => {
        console.error('Error uploading user image:', error);
        return of({ error: 'Failed to upload image' });
      }),
    );
  }

  deleteUserImage(): Observable<any> {
    const uuid = this.getCurrentUserUuid();
    if (!uuid) {
      return of({ error: 'No user UUID available' });
    }

    return this.mizarService.deleteUserImage(uuid).pipe(
      tap(() => {
        this.refreshUserPicture();
      }),
      catchError(error => {
        console.error('Error deleting user image:', error);
        return of({ error: 'Failed to delete image' });
      }),
    );
  }

  private getCurrentUserUuid(): string | null {
    if (this.overrideUuid) {
      return this.overrideUuid;
    }
    let uuid: string | null = null;
    this.store
      .select(selectCurrentUser)
      .pipe(take(1))
      .subscribe((user: any) => {
        uuid = user?.uuid || null;
      })
      .unsubscribe();
    return uuid;
  }

  private createInitialsAvatar(firstName: string, lastName: string): SafeUrl {
    const initials = `${firstName.charAt(0)}${lastName.charAt(
      0,
    )}`.toUpperCase();
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="12" fill="#020535"/>
        <text
          x="12"
          y="12"
          font-family="Roboto,Arial, sans-serif"
          font-size="11"
          font-weight="800"
          fill="white"
          text-anchor="middle"
          alignment-baseline="central"
          letter-spacing="0.25"
        >${initials}</text>
      </svg>`.trim();
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/svg+xml;base64,' + btoa(svg),
    );
  }

  private fetchImageForUuid({
    uuid,
    presigned,
  }: {
    uuid: string;
    presigned: boolean;
  }): Observable<SafeUrl> {
    const getMizarImage = presigned
      ? this.mizarService.getUserImage
      : this.mizarService.getUserImageData;
    return this.mizarService.getDetails(uuid).pipe(
      map(details => details as UserDetails),
      catchError(error => {
        console.error('Error checking image details:', error);
        return of(this.USER_ICON_SVG);
      }),
      switchMap((details: { image_count: number }) => {
        if (!details?.image_count || details.image_count === 0) {
          return of(this.USER_ICON_SVG);
        }
        return getMizarImage.call(this.mizarService, uuid).pipe(
          catchError(error => {
            console.error('Error getting user image:', error);
            return of(this.USER_ICON_SVG);
          }),
          map((res: { image_presigned_url: string; image_data: string }) => {
            const url = this.sanitizer.bypassSecurityTrustResourceUrl(
              presigned
                ? res?.image_presigned_url
                : `data:image/png;base64,${res?.image_data}`,
            );
            return url;
          }),
        );
      }),
    );
  }
}
